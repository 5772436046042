import { FC } from 'react'
import { Helmet } from 'react-helmet'

import { HelmetProps } from './types'

import { Footer } from 'components'

const HelmetLayout: FC<HelmetProps> = ({ children }) => (
  <>
    <Helmet>
      <meta property='og:image' content={`${process.env.PUBLIC_URL}/metaImage.png`} />
    </Helmet>
    <>
      {children}
      <Footer />
    </>
  </>
)

export default HelmetLayout
