import { ReactComponent as BadgeIcon } from 'assets/svg/badge.svg'
import { ReactComponent as BoardIcon } from 'assets/svg/board.svg'
import { ReactComponent as UserIcon } from 'assets/svg/user.svg'
import { ReactComponent as StarIcon } from 'assets/svg/star.svg'

const statisticsList = [
  {
    id: 1,
    title: '4.6 / 5',
    subtitle: 'Average Satisfaction Score',
    Icon: <StarIcon />,
    text: '78% of respondents voted with the Top Score',
  },
  {
    id: 2,
    title: '350+',
    subtitle: 'In-Person Attendees',
    Icon: <UserIcon />,
    text: 'We were thrilled to have a large number of participants join us this year.',
  },
  {
    id: 3,
    title: '20+',
    subtitle: 'Sessions Held',
    Icon: <BoardIcon />,
    text: 'Our event featured a variety of insightful sessions covering numerous AWS topics.',
  },
  {
    id: 4,
    title: '500+',
    subtitle: 'Swags Distributed',
    Icon: <BadgeIcon />,
    text: 'Attendees took home plenty of cool AWS Community Day memorabilia.',
  },
]

export default statisticsList
