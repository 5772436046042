import { FC } from 'react'
import { NavLink } from 'react-router-dom'

import styles from './Sponsors.module.scss'

type SponsorInfo = {
  id: number
  url: string
  image: string
  vacantionUrl: string | null
}

export type SponsorCardInfo = {
  id: number
  categoryName: string
  sponsors: SponsorInfo[]
}

type TSponsorCard = {
  data: SponsorCardInfo
}
const SponsorCard: FC<TSponsorCard> = ({ data }) => {
  const { categoryName, sponsors } = data

  return (
    <div>
      <div className={styles.wrapper__list}>
        <div className={styles.wrapper__list__container}>
          <p className={styles.wrapper__list__container_name}>{categoryName}</p>
          <div className={styles.wrapper__list__container__items}>
            {sponsors.map(sponsor => (
              <div key={sponsor.id}>
                <NavLink
                  key={sponsor.url}
                  target='_blank'
                  to={sponsor.url}
                  className={styles.wrapper__list__container__items__item}
                >
                  <img src={sponsor.image} alt='sponsor' />
                </NavLink>
                {sponsor.vacantionUrl && (
                  <NavLink
                    to={sponsor.vacantionUrl}
                    target='_blank'
                    className={styles.wrapper__list__container__items__item_vacantion_button}
                  >
                    Open Positions
                  </NavLink>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SponsorCard
