import { BrowserRouter } from 'react-router-dom'

import { RoutesWrapper } from 'libraries/router'

import './styles/local.scss'

const App = () => (
  <BrowserRouter>
    <RoutesWrapper />
  </BrowserRouter>
)

export default App
