import type { FC } from 'react'

import styles from './Sponsors.module.scss'
import SponsorCard from './SponsorCard'

import { sponsorsList } from 'utils'

const Sponsors: FC = () => {
  const renderSponsorsList = sponsorsList.map(data => <SponsorCard key={data.id} data={data} />)

  return (
    <section id='SPONSORS' className={styles.wrapper}>
      <div className='container'>
        <p className={styles.wrapper__title}>Meet our Sponsors</p>
        <div className={styles.wrapper__list__gaper}>{renderSponsorsList}</div>
      </div>
    </section>
  )
}

export default Sponsors
