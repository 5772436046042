import type { FC } from 'react'

import Logo from 'assets/images/logo.png'
import videoFile from 'assets/videos/banner.mp4'

import styles from './HeaderBanner.module.scss'

import { ReactComponent as TelegramIcon } from '../../assets/svg/social/telegram.svg'
import { ReactComponent as EmailIcon } from '../../assets/svg/social/email.svg'
import { ReactComponent as MeetupIcon } from '../../assets/svg/social/meetup.svg'
import { ReactComponent as YoutubeIcon } from '../../assets/svg/social/youtube.svg'
import { ReactComponent as LinkedinIcon } from '../../assets/svg/social/linkedin.svg'

const HeaderBanner: FC = () => (
  <section id='BECOME-A-SPEAKER' className={styles.banner}>
    <div className={styles.banner__content}>
      <div className={styles.banner__content__left}>
        <img src={Logo} className={styles.banner__content__left_logo} />
        <p className={styles.banner__content__left_description}>
          Thanks everyone who joined us on 14th of September. <br /> It was truly a blast and we can’t wait to keep
          building together!
        </p>

        <p className={styles.title}>Join Us</p>

        <div className={styles.share}>
          <a className={styles.share_item} href='https://t.me/AWSUGArmeniaChannel' target='_blanck'>
            <TelegramIcon />
          </a>
          <a
            className={`${styles.share_item} ${styles.share_item_linkedin}`}
            href='https://www.linkedin.com/company/aws-user-group-armenia/?viewAsMember=true '
            target='_blanck'
          >
            <LinkedinIcon />
          </a>
          <a
            className={`${styles.share_item} ${styles.share_item_meet}`}
            href='https://www.meetup.com/aws-user-group-armenia/'
            target='_blanck'
          >
            <MeetupIcon />
          </a>
          <a className={styles.share_item} href='https://www.youtube.com/@AWSUGArmenia/about' target='_blanck'>
            <YoutubeIcon />
          </a>
          <a className={styles.share_item} href='mailto:awsugarmenia@gmail.com'>
            <EmailIcon />
          </a>
        </div>
      </div>
    </div>

    <div className={styles.banner__content__right}>
      <div className={styles.banner__content__right__bg} />
      <video
        loop
        muted
        autoPlay
        playsInline
        className='video-player'
        preload='metadata'
        controlsList='nodownload'
        onError={() => console.error('Error loading video')}
      >
        <source src={videoFile} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </div>
  </section>
)

export default HeaderBanner
