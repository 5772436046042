import { useState, FC } from 'react'
import classNames from 'classnames'

import Card from './Card'
import styles from './Agenda.module.scss'

import { agendaList } from 'utils'

const agendaStart = agendaList.filter(item => item.type === 'start')

const agendaTrackOne = agendaList.filter(item => item.type === 'trackOne')
const agendaTrackTwo = agendaList.filter(item => item.type === 'trackTwo')
const agendaWorkshops = agendaList.filter(item => item.type === 'workshops')
const agendaEnd = agendaList.filter(item => item.type === 'end')

const trackList: { [key: number]: any[] } = {
  0: agendaTrackOne,
  1: agendaTrackTwo,
  2: agendaWorkshops,
}
const Agenda: FC = () => {
  const [activeStep, setActiveStep] = useState(0)

  const activeTrack = trackList[activeStep]
  const renderStartList = agendaStart.map(item => <Card key={item.id} data={item} />)
  const renderEndList = agendaEnd.map(item => <Card key={item.id} data={item} />)
  const renderTrackList = activeTrack.map(item => <Card key={item.id} data={item} isTrackList />)

  return (
    <section id='AGENDA' className={styles.wrapper}>
      <div className='container'>
        <h1 className={styles.wrapper__title}>Agenda</h1>
        <p className={styles.wrapper__subtitle}>
          We hope you’re excited for AWS Community Day Armenia 2024! We have a fantastic lineup of talks prepared for
          you by passionate community members, experts, and AWS Heroes from Armenia, Canada, Germany, Spain, Uzbekistan,
          Kazakhstan, and Georgia.
        </p>
        <div className={styles.content}>
          {renderStartList}
          <div className={styles.navigation}>
            <div style={{ transform: `translateX(${activeStep * 100}%)` }} className={styles.navigation__item_spiner} />
            <div
              onClick={() => setActiveStep(0)}
              className={classNames(styles.navigation__item, { [styles.navigation__item_active]: activeStep === 0 })}
            >
              Track 1
            </div>
            <div
              onClick={() => setActiveStep(1)}
              className={classNames(styles.navigation__item, { [styles.navigation__item_active]: activeStep === 1 })}
            >
              Track 2
            </div>
            <div
              onClick={() => setActiveStep(2)}
              className={classNames(styles.navigation__item, { [styles.navigation__item_active]: activeStep === 2 })}
            >
              Workshops
            </div>
          </div>
          {renderTrackList}
          {renderEndList}
        </div>
      </div>
    </section>
  )
}

export default Agenda
