import { useState, FC } from 'react'

import styles from './Header.module.scss'
import NavItem from './NavItem'

import { navigationList } from 'utils'
import { useLockBodyScroll } from 'hooks'

import { ReactComponent as AwsLogo } from '../../assets/svg/logo.svg'

const Header: FC = () => {
  const [isOpened, setIsOpened] = useState(false)

  const renderNavigationList = navigationList.map(({ id, title, Icon, link }: any, index) => (
    <NavItem key={id} title={title} Icon={Icon} link={link} index={index} setIsOpened={setIsOpened} />
  ))

  useLockBodyScroll(isOpened)

  return (
    <header className={styles.wrapper}>
      <nav className={`${styles.wrapper__nav} ${isOpened ? styles.wrapper__nav__active : ''}`}>
        {renderNavigationList}
      </nav>

      <div className={styles.wrapper__nav_mobile}>
        <p className={styles.toogle} onClick={() => setIsOpened(!isOpened)}>
          <span />
        </p>
        <AwsLogo onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
      </div>
    </header>
  )
}

export default Header
