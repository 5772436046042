import UserIcon from 'assets/images/user.png'

import styles from './FeedbackCard.module.scss'

const FeedbackCard = ({ data }: any) => {
  const { name, position, feedback, speacker } = data

  return (
    <div className={styles.wrapper}>
      <p className={styles.wrapper__feedback}>{feedback}</p>
      <div className={styles.wrapper__feedback_user}>
        <img src={speacker?.image ?? UserIcon} alt={name} />
        <div>
          <p>{name}</p>
          <span>{position}</span>
        </div>
      </div>
    </div>
  )
}

export default FeedbackCard
