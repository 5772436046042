import classNames from 'classnames'

import styles from './StatisticsCard.module.scss'
import { statisticsList } from 'utils'

const StatisticsCard = ({ data }: any) => {
  const { title, subtitle, text, Icon } = data

  return (
    <div className={classNames(styles.wrapper, { [styles.wrapper__first]: title === statisticsList[0].title })}>
      <div className={styles.title}>
        {Icon}
        <h3>{title}</h3>
      </div>
      <p className={styles.subtitle}>{subtitle}</p>
      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default StatisticsCard
