import { teamList } from 'utils'

import styles from './Team.module.scss'
import TeamCard from './TeamCard'

const Team = () => {
  const renderTeamList = teamList.map(data => <TeamCard key={data.id} data={data} />)

  return (
    <section className={styles.container} id='TEAM'>
      <div className='container'>
        <h2 className={styles.title}>team</h2>
        <div className={styles.wrapper}>{renderTeamList}</div>
      </div>
    </section>
  )
}

export default Team
