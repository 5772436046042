import { useRef, type FC, useState } from 'react'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'
import type { Swiper as SwiperType } from 'swiper'

import TeamCard from 'components/Team/TeamCard'
import { agendaList } from 'utils'

import styles from './Speakers.module.scss'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useResponsive } from 'hooks'
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg'

const youtubeId = 'https://www.youtube.com/embed/Cq9FxNwlXWk?si=i3y_BIeMuOvdhSCr'

const Speakers: FC = () => {
  const swiperRef = useRef()
  const { isLaptop, isTablet } = useResponsive()
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const slidesPerView = !isLaptop ? 3 : isTablet ? 1 : 2
  const speakersListData = agendaList.flatMap(item => item.speackersList || [])

  const updateIndex = (swiperInstance: SwiperType) => {
    if (swiperInstance === null) return
    const currentSlide = swiperInstance?.activeIndex
    setCurrentIndex(currentSlide)
  }

  const renderSpeakersList = speakersListData.map(data => (
    <SwiperSlide key={data?.id}>
      <TeamCard data={data as any} isSpeakers />
    </SwiperSlide>
  ))

  const pagination = {
    clickable: true,
    renderBullet: function (_: number, className: string) {
      return `<span class="${className}"> </span>`
    },
  }

  return (
    <>
      <div className={styles.wrapper} id='SPEAKERS'>
        <h2 className={styles.wrapper__title}>Speakers</h2>

        <div className={classNames('', styles.wrapper__sizer)}>
          <iframe
            src={youtubeId}
            title='Denis Astahov | AWS Community Day Armenia 2024 | Special Guest Speaker'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerPolicy='strict-origin-when-cross-origin'
            allowFullScreen
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className='container speakerSlider'>
          <Swiper
            loop={isLaptop}
            initialSlide={currentIndex}
            onRealIndexChange={updateIndex}
            onBeforeInit={(swiper: any) => {
              swiperRef.current = swiper as any
            }}
            pagination={pagination}
            modules={[Autoplay, Pagination, Navigation]}
            spaceBetween={28}
            slidesPerView={slidesPerView}
          >
            {renderSpeakersList}
            <button
              disabled={!currentIndex}
              className='swaper--prev'
              onClick={() => {
                if (currentIndex < 3) {
                  ;(swiperRef.current as any)?.slideTo(0)
                } else {
                  ;(swiperRef.current as any)?.slideTo(currentIndex - 3)
                }
              }}
            >
              <ArrowIcon />
            </button>
            <button
              disabled={currentIndex === 14}
              className='swaper--next'
              onClick={() => (swiperRef.current as any)?.slideTo(currentIndex + 3)}
            >
              <ArrowIcon />
            </button>
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default Speakers
