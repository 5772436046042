import { v4 as uuidv4 } from 'uuid'

import AlexeyPhoto from 'assets/images/speakers/alexey.jpg'
import AntonPhoto from 'assets/images/speakers/anton.png'
import DenisPhoto from 'assets/images/speakers/denis.png'
import GagikPhoto from 'assets/images/speakers/gagik.jpg'
import SaidPhoto from 'assets/images/speakers/said.jpg'
import SamPhoto from 'assets/images/speakers/sam.png'
import DazikPhoto from 'assets/images/speakers/dazik.jpg'
import ViktorPhoto from 'assets/images/speakers/viktor.jpg'
import VahanPhoto from 'assets/images/speakers/vahan.jpg'
import MariaPhoto from 'assets/images/speakers/maria.jpg'
import IrinePhoto from 'assets/images/speakers/irine.png'
import MikayelPhoto from 'assets/images/speakers/mikayel.jpg'
import NikitaPhoto from 'assets/images/speakers/nikita.jpg'
import PetrPhoto from 'assets/images/speakers/petr.jpg'
import TigranPhoto from 'assets/images/speakers/tigran.jpg'
import ChristinePhoto from 'assets/images/team/Christine.png'
import HovoPhoto from 'assets/images/team/Hovo.png'

const agendaList = [
  {
    id: uuidv4(),
    date: '09:00 AM',
    title: 'Registration open',
    language: null,
    hall: null,
    info: null,
    type: 'start',
    speackersList: null,
  },
  {
    id: uuidv4(),
    date: '09:45 AM 10:10 AM',
    title: 'Opening ceremony',
    language: 'En',
    hall: 'Manoogian Hall',
    info: null,
    type: 'start',
    speackersList: null,
  },
  {
    id: uuidv4(),
    date: '10:10 AM 10:40 AM',
    title: 'The Power of Many + Quiz',
    language: 'En',
    hall: 'Manoogian Hall',
    info: null,
    type: 'start',
    speackersList: [
      {
        id: uuidv4(),
        name: 'María Encinar',
        position: 'AWS User Group Global Program Lead at AWS',
        image: MariaPhoto,
        link: 'https://www.linkedin.com/in/mariaencinar?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAAFOx9cB-_4crj5hwN79EMQHsC8UuEmdcLo&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BSwptN5x1RDGXCaI8Tc%2FhYw%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
      ,
      {
        id: uuidv4(),
        name: 'Kristiné Armiyants',
        position: 'Cloud Support Engineer at Skyscrapers, Founder and Leader of AWS UG Armenia',
        image: ChristinePhoto,
        link: 'https://www.linkedin.com/in/kristine-armiyants-42b69b12b/',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },
  {
    id: uuidv4(),
    date: '10:40 AM 11:20 AM',
    title: 'Kubernetes Security: Tackling Attacks & Best Practices',
    language: 'En',
    hall: 'Manoogian Hall',
    info: null,
    type: 'start',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Viktor Vedmich',
        position: 'Senior Developer Advocate at AWS',
        image: ViktorPhoto,
        link: 'https://www.linkedin.com/in/vedmich/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3B69Jch7qRSgGACXdoKM8F9A%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },
  {
    id: uuidv4(),
    date: '11:20 AM 12:20 PM',
    title: 'AWS Security',
    language: 'En',
    hall: 'Manoogian Hall',
    info: null,
    type: 'start',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Denis Astahov',
        position: 'AWS Hero Solutions Architect at OpsGuru, Founder of ADV-IT',
        image: DenisPhoto,
        link: 'https://www.linkedin.com/in/denis-astahov/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3B69Jch7qRSgGACXdoKM8F9A%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },
  {
    id: uuidv4(),
    date: '12:20 PM 13:20 PM',
    title: 'MLOps practices',
    language: 'En',
    hall: 'Manoogian Hall',
    info: null,
    type: 'start',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Nikita Fedkin',
        position: 'Amazon Web Services, Solutions Architect at AWS',
        image: NikitaPhoto,
        link: 'https://www.linkedin.com/in/nikitafed/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3B69Jch7qRSgGACXdoKM8F9A%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },
  {
    id: uuidv4(),
    date: '13:20 PM 14:00 PM',
    title: 'Lunch',
    language: 'En',
    hall: null,
    info: null,
    type: 'start',
    speackersList: null,
  },

  // Track 1

  {
    id: uuidv4(),
    date: '14:00 PM 14:45 PM',
    title: 'Real-World Cost Optimization Solutions on AWS',
    language: 'En',
    hall: 'Classroom 213w',
    info: null,
    type: 'trackOne',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Hovhannes Mikayelyan',
        position: 'DevOps Engineer',
        image: HovoPhoto,
        link: 'https://www.linkedin.com/in/hovmikayelyan/',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },

  {
    id: uuidv4(),
    date: '14:45 PM 15:30 PM',
    title: 'Build Context-aware Chatbot with Amazon Bedrock',
    language: 'En',
    hall: 'Classroom 213w',
    info: null,
    type: 'trackOne',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Dazik Akbarov',
        position: 'Lead Software Engineer at EPAM Systems',
        image: DazikPhoto,
        link: 'https://www.linkedin.com/in/saidazizkhon-akbarov/',
        style: {
          objectPosition: 'top',
        },
      },
      {
        id: uuidv4(),
        name: 'Said Akhmed Agitaev',
        position: 'Lead Software Engineers at EPAM Systems',
        image: SaidPhoto,
        link: 'https://www.linkedin.com/in/agitaev?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACAqnnQB7kRBeddqu6YOnWsNxIsM_de8eaQ&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_people%3By7h%2B%2FDU6Q02zCnRSh%2Fj7tA%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },

  {
    id: uuidv4(),
    date: '15:30 PM 16:15 PM',
    title: 'Optimizing Financial Services with Hybrid Cloud Solutions',
    language: 'En',
    hall: 'Classroom 213w',
    info: null,
    type: 'trackOne',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Tigran Hunanyan',
        position: 'Tech. Lead - Digital Channels at Ameriabank',
        image: TigranPhoto,
        link: 'https://www.linkedin.com/in/tigran-hunanyan-42490923?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAATwmxwBIYFaSERWRbln80k9HWrg8zHa00M&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3Bb2g4aTCtQne9JPFQzoSpMA%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },

  {
    id: uuidv4(),
    date: '16:15 PM 17:00 PM',
    title: 'Athena Best Practices and Beyond: The story of S3 bucket cost',
    language: 'En',
    hall: 'Classroom 213w',
    info: null,
    type: 'trackOne',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Alexey Eremin',
        position: 'Cloud Engineering Specialist at FivexL',
        image: AlexeyPhoto,
        link: 'https://www.linkedin.com/in/alexey-eremin/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3B69Jch7qRSgGACXdoKM8F9A%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },

  // Track 2

  {
    id: uuidv4(),
    date: '14:00 PM 14:45 PM',
    title: 'AWS Transit Gateway',
    language: 'En',
    hall: 'Classroom 214w',
    info: null,
    type: 'trackTwo',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Petr Kalmukhyan',
        position: 'Senior DevOps at TSS Paratransit.',
        image: PetrPhoto,
        link: 'https://www.linkedin.com/in/petr-kalmukhyan/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3B69Jch7qRSgGACXdoKM8F9A%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },

  {
    id: uuidv4(),
    date: '14:45 PM 15:30 PM',
    title: 'Just-in-Time Access with AWS IAM Identity Center',
    language: 'En',
    hall: 'Classroom 214w',
    info: null,
    type: 'trackTwo',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Anton Eremin',
        position: 'Cloud Engineering Specialist at FivexL',
        image: AntonPhoto,
        link: 'https://www.linkedin.com/in/anton-eremin-75aab725a/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3B69Jch7qRSgGACXdoKM8F9A%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },

  {
    id: uuidv4(),
    date: '15:30 PM 16:15 PM',
    title: 'Data Modeling in DynamoDB',
    language: 'En',
    hall: 'Classroom 214w',
    info: null,
    type: 'trackTwo',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Sam Mosleh',
        position: 'Senior Software Engineer at Axcient',
        image: SamPhoto,
        link: 'https://www.linkedin.com/in/mosleh-sam?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAEOjGqcBgAemjq9DLSL4tjXtQTPO3_Xx1-E&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BMu%2BCyALbRcOd7ArVqtnNdQ%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },

  {
    id: uuidv4(),
    date: '16:15 PM 17:00 PM',
    title: 'EC2 instance deployment unification across AWS Organization',
    language: 'En',
    hall: 'Classroom 214w',
    info: null,
    type: 'trackTwo',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Mikayel Ghazaryan',
        position: 'Lead Cloud Architect at Aconso AG',
        image: MikayelPhoto,
        link: 'https://www.linkedin.com/in/mkdotam?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAACEU0MBmC54yAROZvn2GMZsQQ4cH5LGl4c&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BBNznvB0yTqepPi4OTvvdcQ%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },

  // Workshops

  {
    id: uuidv4(),
    date: '14:00 PM 15:00 PM',
    title: 'Building SaaS applications on Amazon EKS using GitOps',
    language: 'En',
    hall: 'Classroom 208e',
    info: null,
    type: 'workshops',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Vahan Terzibashian',
        position: 'DevOps Architect at Skyscrapers',
        image: VahanPhoto,
        link: 'https://www.linkedin.com/in/vahan-terzibashian?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAAfqhJgBFjv9A-lSEirphB_gsrfz-vF1OCs&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BmOi2MyFYRkmeHzPa7la48A%3D%3D',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },

  {
    id: uuidv4(),
    date: '15:00 PM 16:00 PM',
    title: 'Startup Security: Techniques to Stay Secure while Building Quickly',
    language: 'En',
    hall: 'Classroom 208e',
    info: null,
    type: 'workshops',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Irine Kokilashvili',
        position: 'Lead System Engineer',
        image: IrinePhoto,
        link: 'https://www.linkedin.com/in/irine-kokilashvili/',
        style: {
          objectPosition: 'top',
        },
      },
    ],
  },

  {
    id: uuidv4(),
    date: '16:00 PM 17:00 PM',
    title: 'Amazon SageMaker MLOps: from idea to production in six steps',
    language: 'En',
    hall: 'Classroom 208e',
    info: null,
    type: 'workshops',
    speackersList: [
      {
        id: uuidv4(),
        name: 'Gagik Khalafyan',
        position: 'Data Scientist at EPAM',
        image: GagikPhoto,
        link: 'https://www.linkedin.com/in/gagik-khalafyan-b033a0211?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAADWg5DMB_dbJdlQXGCxeIEk34bsqF9hjWIM&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BbLS%2FLFmiTgOkdWyRPValEA%3D%3D',
        style: {
          objectPosition: 'center 26%',
        },
      },
    ],
  },

  // End

  {
    id: uuidv4(),
    date: '17:00 PM 17:30 PM',
    title: 'Closing ceremony, more fun, gifts and group photo',
    language: null,
    hall: 'Manoogian Hall',
    info: null,
    type: 'end',
    speackersList: null,
  },
]

export default agendaList
