import { useWindowSize } from '@react-hook/window-size'

// @TODO refactor sizes

const useResponsive = () => {
  const [width] = useWindowSize()

  const isLarge = width < 1600

  const isHD = width < 1366

  const isWideL = width <= 1440

  const isWideM = width > 1024 && width < 1366

  const isWideXl = width > 1024 && width < 1513

  const isWideLarge = width < 1256

  const isWide = width > 1024 && width < 1256

  const isLaptop = width <= 1024

  const isTabletLarge = width <= 986

  const isTabletW = width <= 876

  const isTablet = width < 865

  const isMobile = width <= 665

  const isMobileM = width < 568

  const isMobileS = width < 376

  return {
    isHD,
    isWide,
    isWideL,
    isLarge,
    isWideM,
    isWideXl,
    isLaptop,
    isTablet,
    isMobile,
    isMobileS,
    isMobileM,
    isTabletW,
    isWideLarge,
    isTabletLarge,
  }
}

export default useResponsive
