import { FC } from 'react'
import styles from './Team.module.scss'

import { ReactComponent as LinkedinIcon } from 'assets/svg/linkedin.svg'

type TeamCardInfo = {
  link: string
  name?: string
  image: string
  title: string
  style?: any
  subtitle?: string
  position?: string
}

type TTeamCard = {
  data: TeamCardInfo
  isSpeakers?: boolean
}

const TeamCard: FC<TTeamCard> = ({ data, isSpeakers }) => {
  const { image, title, name, style, subtitle, position, link } = data

  return (
    <div className={styles.card}>
      <div className={styles.card__photo}>
        <img
          style={{
            objectPosition: style?.objectPosition ?? 'top',
            objectFit: style?.objectFit ?? 'cover',
          }}
          src={image}
          alt={title}
        />
      </div>
      <div className={styles.card__content}>
        <p className={styles.card__title}>{isSpeakers ? name : title}</p>
        <p className={styles.card__subtitle}>{isSpeakers ? position : subtitle}</p>
        <a className={styles.card__link} href={link} target='_blank' rel='noreferrer'>
          <LinkedinIcon />
        </a>
      </div>
    </div>
  )
}

export default TeamCard
