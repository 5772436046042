import classNames from 'classnames'
import ScrollIntoView from 'react-scroll-into-view'

import styles from './Header.module.scss'

const NavItem = ({ title, Icon, link, index, setIsOpened }: any) => (
  <ScrollIntoView selector={`#${link}`} className={styles.item__child} onClick={() => setIsOpened(false)}>
    <span>
      <div
        className={classNames(styles.wrapper__content, {
          [styles.wrapper__content_active]: index === 0,
        })}
      >
        {title && <h3>{title}</h3>}

        {Icon && <Icon />}
      </div>
    </span>
  </ScrollIntoView>
)

export default NavItem
