const feedbackList = [
  {
    id: 1,
    name: 'Community Day Attendee',
    position: 'from feedback survey',
    feedback: `"I appreciate the event's organization and the diversity of sessions offered."`,
  },
  {
    id: 3,
    name: 'Community Day Attendee',
    position: 'from feedback survey',
    feedback: `"Overall, it was a valuable experience, and I found the sessions informative and engaging. I look forward to future events and hope to see continued efforts in enhancing diversity, inclusivity, and interactive participation. Thank you for organizing such a fantastic event!"`,
  },
  {
    id: 2,
    name: 'Community Day Attendee',
    position: 'from feedback survey',
    feedback: `"Thanks for such a great experience!! I loved AWS Community Day it was very insightful, interesting and fun."`,
  },
  {
    id: 4,
    name: 'Community Day Attendee',
    position: 'from feedback survey',
    feedback: `"I appreciate the event's organization and the diversity of sessions offered."`,
  },
  {
    id: 5,
    name: 'Community Day Attendee',
    position: 'from feedback survey',
    feedback: `"Thanks for such a great experience!! I loved AWS Community Day it was very insightful, interesting and fun."`,
  },
  {
    id: 6,
    name: 'Community Day Attendee',
    position: 'from feedback survey',
    feedback: `"Overall, it was a valuable experience, and I found the sessions informative and engaging. I look forward to future events and hope to see continued efforts in enhancing diversity, inclusivity, and interactive participation. Thank you for organizing such a fantastic event!"`,
  },
]

export default feedbackList
